import {
  encode
} from 'gpt-tokenizer';

const MAX_TOKENS = 4096;

// export const countTokensForString = async (text) => {
//   if (text === null) {
//     return 0;
//   }

//   //...
// }

export const countTokensForMessages = (
  messages
) => {
  // var typedMessages = (messages as ChatCompletionRequestMessage[])
  // Token limit minus the system prompt
  // const tokenLimit = 4000;
  // const systemPromptSize = 60;
  // const withinTokenLimit = isWithinTokenLimit(text)
  var numTokens = 0;

  for (const message of Object.values(messages)) {
    numTokens += 4;
    // for (var key of Object.values(message.keys)) {
    var value = message['content'];
    console.log('value: ' + value);
    numTokens += encode(value).length;
    if (message['role'] !== undefined) {
      numTokens += encode(message['role']).length;
    }
    if (message['name'] !== undefined) {
      numTokens -= 1;
    }
    // }
  }

  numTokens += 2;

  return numTokens;
}

export const removeTwoMessagesIfGreaterThanMaxTokens = (messages) => {
  var totalTokens = countTokensForMessages(messages);

  //
  // The propmpt size
  //
  totalTokens += 180;

  console.log('totalTokens: ' + totalTokens);

  if (totalTokens > MAX_TOKENS) {
    console.log('Over MAX_TOKENS. numTokens: ' + totalTokens);
    // Return messages with the first 2 removed
    return messages.slice(2);
  } else {
    return messages;
  }
}