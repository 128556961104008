import React, { useState, useRef, useEffect, useCallback } from "react";
import LoadingSpinner from "./LoadingSpinner";
// import { auth, db } from "../firebase";
// import { addDoc, collection, serverTimestamp } from "firebase/firestore";

const SendMessage = ({ sendMessage, canSend, isLoading }) => {
  const [message, setMessage] = useState("");
  const textareaRef = useRef(null);
  const lastFocusedElementRef = useRef(null);

  function handleKeyDown(event) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      // event.target.form.dispatchEvent(new Event('submit', { cancelable: true }));
      if (canSend === true) {
        sendThisMessage(event);
      }
    }
  }

  function handleButtonClick(event) {
    if (lastFocusedElementRef.current === textareaRef.current) {
      textareaRef.current.focus();
    }
  }

  // function handleInput(event) {
  //   const textarea = textareaRef.current;
  //   const { scrollHeight, clientHeight } = textarea;
  //   // const lines = textarea.value.split('\n').length;

  //     if (scrollHeight > clientHeight) {
  //     textarea.style.height = `${Math.min(scrollHeight, 80)}px`;
  //     textarea.style.overflowY = 'scroll';
  //   } else {
  //     textarea.style.height = '40px';
  //     textarea.style.overflowY = 'hidden';
  //   }

  //   setMessage(event.target.value);
  // }

  const handleInput = useCallback(
    (event) => {
      const textarea = textareaRef.current;
      const { scrollHeight } = textarea;

      if (scrollHeight > 40) {
        textarea.style.height = `${Math.min(scrollHeight, 72)}px`;
        textarea.style.overflowY = 'scroll';
      } else {
        textarea.style.height = '40px';
        textarea.style.overflowY = 'hidden';
      }

      setMessage(event.target.value);
    },
    [message]
  );

  const sendThisMessage = async (event) => {
    event.preventDefault();

    // if (lastFocusedElementRef.current === textareaRef.current) {
    //   textareaRef.current.focus();
    // }

    // Validate
    if (message.trim() === "") {
      alert("Please enter a valid question");
      return;
    }
    // const { uid, displayName, photoURL } = auth.currentUser;
    // await addDoc(collection(db, "messages"), {
    //   text: message,
    //   name: displayName,
    //   avatar: photoURL,
    //   createdAt: serverTimestamp(),
    //   uid,
    // });
    sendMessage(message);
    setMessage("");
    const textarea = textareaRef.current;
    textarea.style.height = '40px';
    textarea.style.overflowY = 'hidden';
    // scroll.current.scrollIntoView({ behavior: "smooth" });
  };

  // useEffect(() => {
  //   // textareaRef.current.style.height = '40px';
  //   function handleFocus(event) {
  //     lastFocusedElementRef.current = event.target;
  //   }

  //   document.addEventListener('focusin', handleFocus);

  //   return () => {
  //     document.removeEventListener('focusin', handleFocus);
  //   };
  // }, []);

  return (
    <div>
      <form onSubmit={sendThisMessage} className="send-message" autoComplete="false">
        <label htmlFor="messageInput" hidden>
          Enter Message
        </label>
        <textarea
          id="messageInput"
          name="messageInput"
          type="textarea"
          ref={textareaRef}
          onKeyDown={handleKeyDown}
          maxLength="500"
          // autoFocus={true}
          // type="text"
          // className="send-messages__input"
          className="textareainput"
          autoCapitalize="sentences"
          autoComplete="false"
          placeholder="Type a message..."
          value={message}
          // onChange={(e) => setMessage(e.target.value)}
          onChange={handleInput}
        // aria-multiline="true"
        // rows="1"
        />
        {!isLoading ? (
          <button type="submit" autoComplete="false" disabled={!canSend} onClick={handleButtonClick}>Send</button>
        ) : (
          <LoadingSpinner />
        )}

        {/* <button type="submit" >Send</button> */}
      </form>
      <div className="builtby">DreamChrome © 2023</div>
    </div>
  );
};

export default SendMessage;
