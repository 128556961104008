import React from "react";
// import GoogleSignin from "../img/btn_google_signin_dark_pressed_web.png";
// import { auth } from "../firebase";
// import { useAuthState } from "react-firebase-hooks/auth";
// import { GoogleAuthProvider, signInWithRedirect } from "firebase/auth";

const NavBar = () => {
  // const [user] = useAuthState(auth);

  // const googleSignIn = () => {
  //   const provider = new GoogleAuthProvider();
  //   signInWithRedirect(auth, provider);
  // };

  // const signOut = () => {
  //   auth.signOut();
  // };

  return (
    <nav className="nav-bar">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h1 style={{ margin: 0 }}>Who is God?</h1>
      </div>
      {/* {user ? (
        <button onClick={signOut} className="sign-out" type="button">
          Sign Out
        </button>
      ) : (
        <button className="sign-in">
          <img
            onClick={googleSignIn}
            src={GoogleSignin}
            alt="sign in with google"
            type="button"
          />
        </button>
      )} */}

      <div className="pay-button-container">
        <a href="https://donate.stripe.com/6oEeXRbV50Bg412bII" target=" blank" className="pay-button">
          Donate
        </a>
      </div>
    </nav>
  );
};

export default NavBar;
