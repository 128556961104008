import React, { useEffect, useRef, useState } from "react";
// import {
//   query,
//   collection,
//   orderBy,
//   onSnapshot,
//   limit,
// } from "firebase/firestore";
// import { functions } from "../firebase";
// import { httpsCallable } from "firebase/functions";
import Message from "./Message";
import SendMessage from "./SendMessage";
import NavBar from "./NavBar";
import { removeTwoMessagesIfGreaterThanMaxTokens } from "../helpers/countTokens";
import { firebaseConfigFunctionPrefix } from "../firebaseConfig";
// import jsonlines from 'jsonlines';
// import { loadStripe } from "@stripe/stripe-js"; 

const ChatBox = () => {
  const [messages, setMessages] = useState([
    { "role": "assistant", "content": "Hello, I am here to help you understand who God is. Feel free to ask me questions or even debate with me. Do you have a personal relationship with Jesus?" },
    { "role": "suggestionsheader", "content": "Suggestions:" },
    { "role": "suggestions", "content": "Yes" },
    { "role": "suggestions", "content": "No" },
    { "role": "suggestions", "content": "I'm not sure" },
    // { "role": "user", "content": "I am a bot, so I may not be able to answer all of your questions. If I can't answer your question, I will let you know and you can ask a real person." },
    // { "role": "assistant", "content": "If you would like to talk to a real person, you can click the button below to talk to a pastor." },
    // { "role": "user", "content": "If you would like to talk to a real person, you can click the button below to talk to a pastor." },
    // { "role": "assistant", "content": "If you would like to talk to a real person, you can click the button below to talk to a pastor." },
    // { "role": "user", "content": "If you would like to talk to a real person, you can click the button below to talk to a pastor." },
    // { "role": "assistant", "content": "If you would like to talk to a real person, you can click the button below to talk to a pastor." },
    // { "role": "user", "content": "If you would like to talk to a real person, you can click the button below to talk to a pastor." },
  ]);
  const [canSend, setCanSend] = useState(true);
  // const [containerHeight, setContainerHeight] = useState(window.innerHeight);
  const [isLoading, setIsLoading] = useState(false);
  const [typingInc, setTypingInc] = useState(0);
  const scroll = useRef();
  var isFirstMessage = true;
  var beliefMode = 'christian';

  // const stripePromise = loadStripe(
  //   "pk_test_51M4qNIHLMWKfGAYp0pMMMIdWSLetWEs9dXh0MPhbXrlufNNynumWwqRK38rrMcNPtQqsIA7hobjz2z914e3Pr1wT00NW4d7M7q"
  // );

  // useEffect(() => {
  //   const q = query(
  //     collection(db, "messages"),
  //     orderBy("createdAt"),
  //     limit(50)
  //   );

  //   const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
  //     let messages = [];
  //     QuerySnapshot.forEach((doc) => {
  //       messages.push({ ...doc.data(), id: doc.id });
  //     });
  //     setMessages(messages);
  //   });
  //   return () => unsubscribe;
  // }, []);

  useEffect(() => {
    // Scroll to the bottom of the messages div if it was already scrolled to the bottom before the new message was added
    // if (scroll.current.scrollTop + scroll.current.clientHeight >= scroll.current.scrollHeight - 10) {
    //   scroll.current.scrollTop = scroll.current.scrollHeight;
    // }

    if (typingInc % 10 === 0 || typingInc === 0 || typingInc === 1) {
      setTimeout(() => {
        scroll.current.scrollIntoView({ behavior: "smooth" });
      }, 800);
    }

    // function handleResize() {
    //   setContainerHeight(window.innerHeight);
    // }

    // window.addEventListener('resize', handleResize);
    // return () => {
    //   window.removeEventListener('resize', handleResize);
    // };
  }, [typingInc, isLoading]);

  // async function that accepts a message, adds it to the messages state variable, then calls a Firebase callable function to send the message and adds the response to the messages state variable
  const sendMessage = async (newMessage) => {
    if (!canSend) {
      return;
    }

    // Disable the send button
    setCanSend(false);
    setIsLoading(true);

    // If the last messages has a role of suggestions, then remove it
    while (messages[messages.length - 1].role === "suggestions"
      || messages[messages.length - 1].role === "suggestionsheader") {
      messages.pop();
    }

    // Remove the first message and answer if it is the default message
    if (!isFirstMessage) {
      if (messages[0].content === "Hello, I am here to help you understand who God is. Feel free to ask me questions or even debate with me. Do you have a personal relationship with Jesus?") {
        messages.shift();
        messages.shift();
      }
      // Keep track
      isFirstMessage = false;
    } else {
      switch (newMessage.toLowerCase()) {
        case 'yes':
          beliefMode = 'christian';
          break;
        case 'no':
          beliefMode = 'atheist';
          break;
        default:
          beliefMode = 'unsure';
          break;
      }
    }

    // Make a new messages array with only the last 6 messages
    var workingMessages = [...messages.slice(-6), { "role": "user", "content": newMessage }];

    // Make sure the array is not too many tokens
    workingMessages = removeTwoMessagesIfGreaterThanMaxTokens(workingMessages);

    // Add the new message to the messages state variable
    setMessages(prevMessages => [...prevMessages, { "role": "user", "content": newMessage }]);

    // call the Firebase callable function 'addMessage' and get the result
    // const functions = getFunctions();
    // const queryAi = httpsCallable(functions, 'aiQueryAi');
    // var result = await queryAi({ "prompt": workingMessages });

    var completeResponse = "";

    fetch(firebaseConfigFunctionPrefix + 'aiQueryAiStreamV2', {
      method: 'POST',
      body: JSON.stringify({
        "messages": workingMessages,
        "beliefmode": beliefMode,
      }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => {
        const readable = response.body.getReader();
        var justStarted = true;
        var suggestionMode = false;

        // const stream = response.body.pipeThrough(new TextDecoderStream()).pipeThrough(jsonlines.parse());

        // stream.on('data', (data) => {
        //   // console.log(data);
        //   // var thisBit = new TextDecoder("utf-8").decode(data);
        //   var thisBit = data;
        //   completeResponse += thisBit["text"];

        //   // // Remove 'As an AI language model, ' or 'As a Christian, ' with or without a comma from the beginning of the message in result.data
        //   // var sanitizedMessage = completeResponse.replace(/^As an AI language model, |^As a Christian, |^As an AI language model |^As a Christian /, "");

        //   // Save to state
        //   if (justStarted) {
        //     setMessages(prevMessages => [...prevMessages, { "role": "assistant", "content": completeResponse }]);
        //     setTypingInc(prevTypingInc => prevTypingInc + 1);
        //     justStarted = false;
        //   } else {
        //     // console.log("completeResponse: " + completeResponse);
        //     setMessages(prevMessages => {
        //       prevMessages[prevMessages.length - 1].content = completeResponse;
        //       return prevMessages;
        //     });
        //     setTypingInc(prevTypingInc => prevTypingInc + 1);
        //   }
        // });

        // setCanSend(true);
        // setIsLoading(false);
        // setTypingInc(0);

        (async function read() {
          let hasDoneAtLeastOneSuggestion = false;
          let buffer = '';
          let suggestionBuffer = '';

          while (true) {
            const { done, value } = await readable.read();

            if (done) {
              // Enable the send button
              setCanSend(true);
              setIsLoading(false);
              setTypingInc(0);
              break;
            }

            const text = new TextDecoder('utf-8').decode(value);
            const delimiterIndex = text.indexOf('\u0003');

            if (!suggestionMode) {
              if (delimiterIndex === -1) {
                // No delimiter found, process everything with suggestionMode = false
                buffer += text;
                completeResponse += buffer;
                if (completeResponse) {
                  if (justStarted) {
                    setMessages(prevMessages => [...prevMessages, { "role": "assistant", "content": completeResponse }]);
                    setTypingInc(prevTypingInc => prevTypingInc + 1);
                    justStarted = false;
                  } else {
                    // console.log("completeResponse: " + completeResponse);
                    setMessages(prevMessages => {
                      prevMessages[prevMessages.length - 1].content = completeResponse;
                      return prevMessages;
                    });
                    setTypingInc(prevTypingInc => prevTypingInc + 1);
                  }
                }
                buffer = '';
              } else {
                // Delimiter found, process everything before the delimiter with suggestionMode = false
                // and everything after the delimiter with suggestionMode = true
                buffer += text.slice(0, delimiterIndex);
                suggestionBuffer += text.slice(delimiterIndex + 1);
                completeResponse += buffer;
                if (completeResponse) {
                  if (justStarted) {
                    setMessages(prevMessages => [...prevMessages, { "role": "assistant", "content": completeResponse }]);
                    setTypingInc(prevTypingInc => prevTypingInc + 1);
                    justStarted = false;
                  } else {
                    // console.log("completeResponse: " + completeResponse);
                    setMessages(prevMessages => {
                      prevMessages[prevMessages.length - 1].content = completeResponse;
                      return prevMessages;
                    });
                    setTypingInc(prevTypingInc => prevTypingInc + 1);
                  }
                }
                buffer = '';
                suggestionMode = true;
              }
            } else {
              suggestionBuffer += text.slice(delimiterIndex + 1);
            }

            if (suggestionMode && suggestionBuffer) {
              const lines = suggestionBuffer.split('\n');
              suggestionBuffer = lines.pop();
              for (const line of lines) {
                if (line.trim()) {
                  if (!hasDoneAtLeastOneSuggestion) {
                    setMessages(prevMessages => [
                      ...prevMessages,
                      { role: 'suggestionsheader', content: 'Suggestions:' },
                    ]);
                    hasDoneAtLeastOneSuggestion = true;
                  }
                  setMessages(prevMessages => [
                    ...prevMessages,
                    { role: 'suggestions', content: line.trim() },
                  ]);
                  setTypingInc(prevTypingInc => {
                    const nextMultipleOfFive = Math.ceil(prevTypingInc / 10) * 10;
                    return nextMultipleOfFive === prevTypingInc ? nextMultipleOfFive + 5 : nextMultipleOfFive;
                  });
                }
              }
            }
          }
        })();
      })
      .catch(error => { console.log(error); });

    // check result for errors
    // if (result.data.error) {
    //   // handle error
    //   return;
    // }
    // get the sanitized message from the result
    // var sanitizedMessage = result.data.text;
    // Add the sanitized message to the messages state variable

    // // Remove 'As an AI language model, ' or 'As a Christian, ' with or without a comma from the beginning of the message in result.data
    // var sanitizedMessage = returnVal.replace(/^As an AI language model, |^As a Christian, |^As an AI language model |^As a Christian /, "");

    // // Save to state
    // setMessages(prevMessages => [...prevMessages, { "role": "assistant", "content": sanitizedMessage }]);

    // // Enable the send button
    // setCanSend(true);
    // setIsLoading(false);
  };

  return (
    <main className="chat-box">
      {/* <span ref={scroll}></span> */}
      <NavBar />

      <div className="messages-wrapper">
        {/* <div className="messages-list"> */}
        {/* <div style={{ minHeight: '50px' }}></div> */}
        {messages?.slice(0).reverse().map((message, index) => (
          <Message key={index} message={message} onClick={sendMessage} />
        ))}
        {/* </div> */}
        {/* <div style={{ minHeight: '60px' }}></div> */}
      </div>
      {/* when a new message enters the chat, the screen scrolls dowwn to the scroll div */}
      {/* <span ref={scroll}></span> */}
      <div key={'scrollerDiv'} ref={scroll} style={{ height: '0px' }}></div>

      <SendMessage sendMessage={sendMessage} canSend={canSend} isLoading={isLoading} />
    </main>
  );
};

export default ChatBox;
