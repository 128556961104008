import React from "react";
// import { auth } from "../firebase";
// import { useAuthState } from "react-firebase-hooks/auth";

const Message = ({ message, onClick }) => {
  // const [user] = useAuthState(auth);
  // console.log(message.avatar);

  const sendThisMessage = async (event) => {
    onClick(message.content);
  }

  if (message.role === "suggestionsheader") {
    return (
      <div className="chat-bubble suggestionsheader">
        <div className="bubble__right">
          <p className="suggestions__message">{message.content}</p>
        </div>
      </div>
    );
  }
  if (message.role === "suggestions") {
    return (
      <button className="chat-bubble suggestions" onClick={sendThisMessage}>
        <p className="suggestions__message">{message.content}</p>
      </button>
    );
  }
  return (
    <div
      className={`chat-bubble ${message.role !== "assistant" ? "right" : ""}`}>
      {/* <img
        className="chat-bubble__left"
        src={message.avatar}
        alt="user avatar"
      /> */}
      <div className="chat-bubble__right">
        {/* <p className="user-name">{message.role}</p> */}
        <p className="user-message">{message.content}</p>
      </div>
    </div>
  );
};

export default Message;