// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyBsvjS3bUrAzkgqsQNkAtGxiL5vCm1lL2c',
  appId: '1:697084136836:web:74befd26c7de45a05b21d1',
  messagingSenderId: '697084136836',
  projectId: 'wigai-dev',
  authDomain: 'wigai-dev.firebaseapp.com',
  storageBucket: 'wigai-dev.appspot.com',
  measurementId: 'G-P19HLW05S5',
};

var prefix = "";
// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
  prefix = 'http://127.0.0.1:5001/wigai-dev/us-east4/';
} else {
  prefix = 'https://us-east4-wigai-dev.cloudfunctions.net/';
}
export const firebaseConfigFunctionPrefix = prefix;